import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import {
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Grid,
} from '@material-ui/core';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import { OutletIcon } from 'assets';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetAssignedOutletByUserQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const DigitalReportingSubmenu = (props: any) => {
	useEffect(() => {
		console.log('DigitalReportingSubmenu');
	}, []);
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const history = useHistory();
	const { outletID } = useParams<any>();

	const [openSwitch, setOpenSwitch] = useState(null);

	const { handlePermHidden } = usePermissionChecker();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const [filterReportStatus, setFilterReportStatus] = useState('ALL');
	const reportSearch = JSON.parse(localStorage.getItem('searchFilter'));
	const [rerender, setRender] = useState(false);
	const [rerender1, setRender1] = useState(false);

	const [filterUserStatus1, setFilterReportStatus1] = useState();

	const {
		filteredList: filteredListDigitalReportInfo,
		handleSearch: handleSearchDigitalReportInfo,
		setOriginalListing: setOriginalListingDigitalReportInfo,
	} = useFuseSearch();

	console.log(filteredListDigitalReportInfo);
	console.log(rerender);

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	// ACCOUNTX API CALLS

	const submenuList = [
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/outlet-sales-revenue`,
			title: `Outlet Revenue Report`,
			permission: PosPermission.DigitalReportingOutletSalesRevenueShow,
			status: 'STATISTICS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/meal-period-collection`,
			title: `Meal Period Collection`,
			permission: PosPermission.DigitalReportingMealPeriodCollectionShow,
			status: 'STATISTICS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/production-analysis-major-family-item`,
			title: `Production Analysis Summary`,
			permission: PosPermission.DigitalReportingProductionAnalysisSummaryShow,
			status: 'STATISTICS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/production-analysis-outlet`,
			title: `Production Analysis By Outlet`,
			permission: PosPermission.DigitalReportingProductionAnalysisByOutletShow,
			status: 'STATISTICS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/daily-earning`,
			title: `Daily Earning Report`,
			permission: PosPermission.DigitalReportingDailyEarningReportShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/cashier-collection`,
			title: `Cashier Collection Report`,
			permission: PosPermission.DigitalReportingCashierCollectionReportShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/day-end`,
			title: `Day End Report`,
			permission: PosPermission.DigitalReportingDayEndReportShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/bill-trans-list`,
			title: `Bill Transaction Listing`,
			permission: PosPermission.DigitalReportingBillTransactionListingShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/discount-list`,
			title: `Discount Listing`,
			permission: PosPermission.DigitalReportingDiscountListingShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/user-activity-log-report`,
			title: `User Activity Log Report `,
			permission: PosPermission.DigitalReportingAuditUserLoggingSystemShow,
			status: 'OTHERS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/voided-trans-list`,
			title: `Voided Transactions Listing`,
			permission: PosPermission.DigitalReportingVoidedTransactionListingShow,
			status: 'CASHIERING',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/meal-period-sales`,
			title: `Meal Period Sales Analysis Report`,
			permission: PosPermission.DigitalReportingMealPeriodSalesShow,
			status: 'STATISTICS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/menu-items-master-file-listing`,
			title: `Menu Items Master File Listing`,
			permission: PosPermission.DigitalReportingMenuItemsMasterFileListingShow,
			status: 'OTHERS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/digital-menu-pricing-listing`,
			title: `Digital Menu & Pricing Listing`,
			permission: PosPermission.DigitalReportingDigitalMenuPricingListingShow,
			status: 'OTHERS',
		},
		{
			path: `/menu/outlet-app/${outletID}/digital-reporting/sales-and-services`,
			title: `Sales & Services Tax Listing`,
			permission: PosPermission.DigitalReportingSalesServicesListingShow,
			status: 'FINANCE',
		},
	];

	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	useEffect(() => {
		if (reportSearch?.value !== '') {
			setFilterReportStatus1(reportSearch?.value);
		}
	}, [rerender]);

	useEffect(() => {
		if (submenuList && submenuList?.length > 0) {
			setOriginalListingDigitalReportInfo(submenuList);
		}
	}, [rerender]);

	useEffect(() => {
		if (!rerender && submenuList?.length > 0 && !!reportSearch?.value) {
			handleSearchDigitalReportInfo(`'"${reportSearch?.value ?? ''}"`, [
				'title',
			]);
			setRender1(true);
		}
	}, [rerender, rerender1]);

	return (
		<>
			<MainHeader
				mainBtn="menu"
				onClick={handleDrawer}
				smTitle={''}
				title={outletArray[0]?.name}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Digital Reporting', current: true },
				]}
			/>

			<div
				className={
					globalState.drawerOpen
						? 'search-filter with-dropdown-filter-general-settings'
						: 'search-filter with-dropdown-filter-general-settings-fw'
				}
			>
				<Grid container>
					<Grid item xs={8} style={{ paddingBottom: '4px', marginLeft: '0' }}>
						<SearchHeader
							title="Digital Report Listing "
							value={`${
								submenuList?.filter(e => {
									if (filterReportStatus === 'ALL') {
										return e;
									} else {
										return e?.status === filterReportStatus;
									}
								})?.length
							}`}
							// value={`${filteredList?.length ?? filterUserStatus?.length ?? 0}`}
							onChangeAction={e => {
								handleSearchDigitalReportInfo(`'"${e?.target?.value}"`, [
									'title',
								]);
								localStorage.setItem(
									`searchFilter`,
									JSON.stringify({
										value: e?.target?.value,
									}),
								);
								setRender(true);
								setTimeout(() => {
									setRender(false);
								}, 200);
							}}
							search
							// isDefaultValue={userSearch?.value ?? false}
							defaultValue={reportSearch?.value ?? ''}
							onCloseAction={() => {
								handleSearch('', []);
							}}
						/>
					</Grid>
					<Grid item xs={4} style={{ paddingBottom: '4px' }}>
						<DropdownBar
							options={[
								{
									name: 'All',
									ID: 'ALL',
								},
								{
									name: 'Cashiering ',
									ID: 'CASHIERING',
								},
								{
									name: 'Finance',
									ID: 'FINANCE',
								},
								{
									name: 'Statistic',
									ID: 'STATISTICS',
								},
								{
									name: 'Others',
									ID: 'OTHERS',
								},
							]}
							setOptionID={setFilterReportStatus}
							borderRadius="4px"
						/>
					</Grid>
				</Grid>
			</div>
			<ContentWrapper searchBarDropdown>
				<List className="core-list">
					{filteredListDigitalReportInfo
						?.filter(e => {
							if (filterReportStatus === 'ALL') {
								return e;
							} else if (filterReportStatus !== 'ALL') {
								return e?.status === filterReportStatus;
							}
						})
						?.map((el, index) => {
							if (
								handlePermHidden({
									outletID: outletID,
									permEnum: el.permission,
								})
							)
								return (
									<ListItem key={index} onClick={() => history.push(el?.path)}>
										<ListItemText
											style={{
												textAlign: 'right',
												marginLeft: '5px',
											}}
											primary={<span className="xsTitle">{el?.title}</span>}
										/>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													onClick={() =>
														history.push({
															pathname: el?.path,
														})
													}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
						})}
				</List>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div
											className="title"
											style={{ color: '#ff9800' }}
										>{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												// className="m-b-20"
												id="standard-basic"
												label="Search here..."
												fullWidth
												// value={`${filteredList?.name ?? 0}`}
												onChange={e => {
													handleSearch(`'"${e?.target?.value}"`, ['name']);
												}}
												// fixed
												// search
												// onClose={() => handleSearch('', [])}
											/>
										</ListItem>
									</List>
								</div>
							) : null}
							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList?.map((outlet, index) => (
										<>
											<ListItem
												style={{
													backgroundColor:
														outlet?.ID === outletID ? '#E5EDFF' : null,
												}}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/digital-reporting`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
														// loadCurrentMealPeriod({
														// 	variables: { outletID: outlet?.ID },
														// });
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
