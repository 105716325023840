import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlertWIcon } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Card,
	Divider,
	Fab,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import Person from '@material-ui/icons/Person';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetTableDocument,
	TableStatus,
	useCheckCurrentMealPeriodLazyQuery,
	useGetAssignedOutletByUserQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetReceiptPrinterQuery,
	useGetTableQuery,
	useRefreshOrderItemSubscription,
	useRefreshTableSubscription,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import { WSclient, menuxSocketUrl } from 'POSClient';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	ChairSeatIcon,
	OutletIcon,
	PersonIcon,
	ServedIcon,
} from '../../../../assets';
import './grid.scss';
import { CashDrawer } from '../../../../../../outlet-react/src/assets/index';
import { openDrawer } from '../TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';
import io from 'socket.io-client';
import { SocketContext } from 'containers/Socket/SocketContext';
import { dateFormat } from 'helpers/formatDate';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';

const iconMap: Record<TableStatus, string> = {
	[TableStatus.Available]: ChairSeatIcon,
	[TableStatus.Occupied]: ServedIcon,
	[TableStatus.Reserved]: PersonIcon,
};

const bgcolorMap: Record<TableStatus, string> = {
	[TableStatus.Available]: 'rgba(170,252,118,1)',
	[TableStatus.Occupied]: 'rgba(255,52,92,1)',
	[TableStatus.Reserved]: 'rgba(162,162,162,1)',
};

export interface TablesListingState {
	selectedTable: string;
}

export const TableGrid = () => {
	useEffect(() => {
		console.log('TableGrid');
	}, []);
	const history = useHistory();
	const { outletID } = useParams<any>();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const tableSearch = JSON.parse(localStorage.getItem('searchFilterTable'));

	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		const savedSearch = localStorage.getItem('searchFilterTable');
		if (savedSearch) {
		  const { tableSearch } = JSON.parse(savedSearch);
		  setSearchTerm(tableSearch || '');
		}
	  }, []);
	//////// Meal Period Date/Time /////////////

	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [selectedTable, setSelectedTable] = useState(null);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { handlePermDisabled } = usePermissionChecker();

	///////////////////////////////////////////////////////////
	///////////////////	QUERIES & MUTATIONS ///////////////////
	///////////////////////////////////////////////////////////

	// const {
	// 	data: { getOutlet } = { getOutlet: [] },
	// 	loading: outletLoading,
	// } = useGetOutletQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: () => {
	// 		loadAllMealPeriod();
	// 	},
	// });

	const {
		data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		called: receiptPrinterCalled,
		loading: receiptPrinterLoading,
	} = useGetReceiptPrinterQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			isDefault: true,
		},
	});

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			// loadCurrentMealPeriod({
			// 	variables: {
			// 		outletID: outletID,
			// 	},
			// });
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const [
		checkCurrentMealPeriod,
		{ loading: checkCurrentMealPeriodLoading },
	] = useCheckCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			let selectedOutlet = getAssignedOutletByUser?.filter(
				outlet => outlet?.ID === outletID,
			)[0];
			if (getCurrentMealPeriod) {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/table/grid/${
						selectedTable?.ID
					}/qr-code/${null}`,
					state: selectedTable,
				});
				// if (selectedOutlet?.customerPolicy?.selfOrder) {
				// 	history.push({
				// 		pathname: `/menu/outlet-app/${outletID}/table/grid/${selectedTable?.ID}/qr-code`,
				// 	});
				// } else if (!selectedOutlet?.customerPolicy?.selfOrder) {
				// 	history.push({
				// 		pathname: `/menu/outlet-app/${outletID}/table/grid/${
				// 			selectedTable?.ID
				// 		}/qr-code/${null}`,
				// 		state: selectedTable,
				// 	});
				// }
			}
		},
		onError: () => {
			setOpenSnackBar(true);
			setSnackBarMsg('No applicable meal period');
		},
	});

	const {
		data: { getTable } = { getTable: [] },
		loading: tableLoading,
		refetch: tableRefetch,
		fetchMore,
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'sequence', isActive: true },
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});
	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	//subscription //subscription //subscription
	const {
		loading: refreshTableLoading,
		data: { refreshTable } = { refreshTable: {} as any },
	} = useRefreshTableSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	const {
		loading: refreshOrderItemLoading,
		data: { refreshOrderItem } = { refreshOrderItem: {} as any },
	} = useRefreshOrderItemSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	useEffect(() => {
		if (refreshTable?.ID) {
			fetchMore({
				query: GetTableDocument,
				variables: {
					outletID: outletID,
					orderByAsc: 'sequence',
					isActive: true,
				},
			});
		}
	}, [refreshTable?.ID]);

	useEffect(() => {
		if (refreshOrderItem?.ID) {
			fetchMore({
				query: GetTableDocument,
				variables: {
					outletID: outletID,
					orderByAsc: 'sequence',
					isActive: true,
				},
			});
		}
	}, [refreshOrderItem?.ID]);

	const [state, setState] = useState('');
	const [openSwitch, setOpenSwitch] = useState(null);

	const [date, setDate] = useState(new Date());

	const {
		filteredList,
		handleSearch,
		setOriginalListing,
		reset,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	////////////////////////////////////////
	/////////FETCH ALL MEAL PERIOD//////////
	////////////////////////////////////////

	// const [
	// 	loadAllMealPeriod,
	// 	{
	// 		called: allMealPeriodCalled,
	// 		loading: allMealPeriodLoading,
	// 		data: { getAllMealPeriod } = { getAllMealPeriod: [] },
	// 	},
	// ] = useGetAllMealPeriodLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: ({ getAllMealPeriod }) => {
	// 		localStorage.setItem('allMealPeriod', JSON.stringify(getAllMealPeriod));
	// 	},
	// });
	const { socket }: any = useContext(SocketContext);
	// const [socket, setSocket] = useState(null);

	// useEffect(() => {
	// 	const newSocket = io(menuxSocketUrl);

	// 	setSocket(newSocket);

	// Cleanup function
	// 	return () => {
	// 		newSocket.disconnect();
	// 	};
	// }, []);

	useEffect(() => {
		if (socket) {
			socket.on('notification', notification => {
				const refreshInterval = setInterval(() => {
					tableRefetch();
				}, 1000);

				return () => {
					clearInterval(refreshInterval);
				};
			});
		}
	}, [socket]);

	return (
		<>
			{tableLoading && <Loading />}
			{getAssignedOutletByUserLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={
					getAssignedOutletByUser?.filter(outlet => outlet?.ID === outletID)[0]
						?.name
				}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[{ name: 'Table Layout', current: true }]}
				// HC
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${dateFormat(
									getAssignedOutletByUser?.filter(
										outlet => outlet?.ID === outletID,
									)[0]?.businessDate,
							  )} ${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
						current: true,
					},
				]}
			/>

			{/* <DynamicSubHeader
				title={state}
				handleClick={handleClickListItem}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					></StyledMenu>
				}
			/> */}

			<ContentWrapper
				tableHeader
				style={{
					marginBottom: isDesktop ? '0px' : '0px',
					marginTop: isDesktop ? '60px' : '0px',
				}}
			>
				<MsgAlertWIcon
					icon={<TouchAppIcon style={{ color: '#FFB11F' }} />}
					message={'Tap to select Table.'}
				/>
			</ContentWrapper>
			
			<ContentWrapper
			dynamicInfoSearch
				float
				style={{
					marginBottom: '50px',
					marginTop: isDesktop ? '190px' : '90px',
				}}
			>

				<SearchHeader
					title="Search Table"
					value={`${getTable?.length}`}
					onChangeAction={e => {
						const searchValue = e?.target?.value || '';
						setSearchTerm(searchValue);
						handleSearch1(`'"${searchValue}"`, ['name']);
						localStorage.setItem(
						  'searchFilterTable',
						  JSON.stringify({
							tableSearch: searchValue,
						  }),
						);
					  }}
					fixed
					search
					datePickerSubInfo
					isDefaultValue={!!tableSearch?.tableSearch ?? false}
					defaultValue={tableSearch?.tableSearch ?? ''}
					onCloseAction={() => {
						localStorage.removeItem('searchFilterTable');
						handleSearch1('', []);
						setSearchTerm(''); 
					}}
					clickText
				/>
				{/* <MsgAlert
					icon={<TouchAppIcon style={{ color: '#FFB11F', fontSize: '20px' }} />}
					message={'Tap to select Table.'}
				/> */}
				<List className="core-list" style={{ paddingLeft: '2%' }}>
					{!tableLoading &&
					!getAssignedOutletByUserLoading &&
					!getCurrentMealPeriodLoading &&
					(getTable === undefined || getTable?.length === 0) ? (
						<EmptyList title="No Records found" />
					) : null}
					{getTable?.filter(
						(x) => x.tablePrfixNumberInfo.toLowerCase().includes(searchTerm.toLowerCase()) 
						).map((el, index) => (
						<ListItem
							onClick={() => {
								if (el?.status === TableStatus.Occupied) {
									history.push(
										`/menu/outlet-app/${outletID}/table/grid/${el?.ID}`,
									);
								} else {
									// history.push({
									// 	pathname: `/menu/outlet-app/${outletID}/table/grid/${el?.ID}/qr-code`,
									// });
									setSelectedTable(el);
									checkCurrentMealPeriod({
										variables: {
											outletID,
										},
									});
								}
							}}
							// disabled={
							// 	el?.status === TableStatus.Available
							// 		? handlePermDisabled({
							// 				outletID: outletID,
							// 				permEnum: PosPermission.HomeTableLayoutNewOrderCreate,
							// 		  })
							// 		: null
							// }
							className="outlet-table"
							style={{
								backgroundColor:
									el?.status === TableStatus.Occupied
										? '#FF4568'
										: el?.reservationInfo?.reserved &&
										  !el?.reservationInfo?.noShow
										? '#ACACAC'
										: el?.status === null || TableStatus.Available
										? '#B2FC74'
										: null,
								paddingLeft: '4px',
								paddingTop: '0',
								paddingRight: '3px',
								// width: '100%',
							}}
						>
							<ListItemText
								className="outlet-table-ul"
								primary={
									<>
										<span className="xsTitle flex-space">
											<Grid
												component={Card}
												style={{
													background: '#FFB11F',
													boxShadow: 'none',
													marginTop: '4px',
												}}
												className="outlet-table-label"
											>
												<Typography
													variant="caption"
													style={{
														fontSize: '10px',
														fontWeight: 800,
													}}
													className="outlet-table-typography"
												>
													{el?.prefix}
													{el?.tableNumber}
												</Typography>
											</Grid>
										</span>
										<span
											style={{
												fontWeight: 600,
												paddingRight: '2px',
												fontSize: '12px',
											}}
										>
											{el?.status === TableStatus.Occupied ? (
												<Person
													style={{
														fontSize: '15px',
														color: '#ff9800',
														paddingTop: '4px',
														boxSizing: 'border-box',
													}}
												/>
											) : el?.reservationInfo?.reserved &&
											  !el?.reservationInfo?.noShow ? (
												<Person
													style={{
														fontSize: '15px',
														color: '#ff9800',
														paddingTop: '4px',
														boxSizing: 'border-box',
													}}
												/>
											) : (
												<EventSeatIcon
													style={{
														fontSize: '15px',
														color: '#ff9800',
														paddingTop: '4px',
														boxSizing: 'border-box',
													}}
												/>
											)}
											{//if occupied
											el?.status === TableStatus.Occupied
												? el?.orderInfo?.totalPax
												: // if reserved
												el?.reservationInfo?.reserved &&
												  !el?.reservationInfo?.noShow
												? el?.reservationInfo?.pax
												: //if available
												  el?.seatCount}
										</span>
									</>
								}
								secondary={
									<>
										<span
											className="desc"
											style={{
												color: 'black',
												textAlign: 'right',
												padding: 0,
												width: '100%',
											}}
										>
											<>
												<span
													className="xxTitle"
													style={{
														color: 'black',
													}}
												>
													{el?.reservationInfo?.reserved &&
													!el?.reservationInfo?.noShow ? (
														el?.status === TableStatus.Occupied ? (
															<br />
														) : (
															<span>{el?.reservationInfo?.name}</span>
														)
													) : (
														<br />
													)}
												</span>
												<span
													style={{
														color: 'black',
													}}
												>
													{el?.status === TableStatus.Occupied
														? `${getAssignedOutletByUser?.filter(
																outlet => outlet?.ID === outletID,
														  )[0]?.currencyCode ?? ''}${amtStr(
																el?.orderInfo?.totalAmount,
														  )}`
														: el?.reservationInfo?.reserved &&
														  !el?.reservationInfo?.noShow
														? formatTime(el?.reservationInfo?.time)
														: null}
												</span>
											</>
										</span>
									</>
								}
							/>
						</ListItem>
					))}
				</List>
			</ContentWrapper>

			{/* <FloatButton
        onClick={() => {

        }}
      /> */}

			<Fab
				color="primary"
				className="float-btn"
				onClick={() => {
					openDrawer(getReceiptPrinter[0]?.name);
				}}
			>
				<img color={'inherit'} src={CashDrawer} width={'37px'} />
			</Fab>

			{/* {handlePermDisabled({
				outletID: outletID,
				permEnum: PosPermission.HomeTableLayoutTakeAwayView,
			}) === false && (
				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Takeaway',
							onClick: () => {
								history.push({
									pathname: `/menu/outlet-app/${outletID}/takeaway`,
								});
							},
							color: 'primary',
							disabled: handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.HomeTableLayoutTakeAwayView,
							}),
						},
					]}
				/>
			)} */}
			<CommonDialogV2
				fullWidth={true}
				open={openSwitch}
				onClose={() => {
					setOpenSwitch(false);
					reset();
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div
											className="title"
											style={{ color: '#ff9800' }}
										>{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												// className="m-b-20"
												id="standard-basic"
												label="Search here..."
												fullWidth
												// value={`${filteredList?.name ?? 0}`}
												onChange={e => {
													handleSearch1(`'"${e?.target?.value}"`, ['name']);
													localStorage.setItem('searchFilter', e.target.value);
													localStorage.removeItem('searchFilterTable');
												}}
												// fixed
												// search
												// onClose={() => handleSearch('', [])}
											/>
										</ListItem>
									</List>
								</div>
							) : null}
							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList?.map((outlet, index) => (
										<>
											<ListItem
												key={outlet?.ID}
												style={{
													backgroundColor:
														outlet?.ID === outletID ? '#E5EDFF' : null,
												}}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/table/grid`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
														localStorage.removeItem('searchFilterTable');
														// loadCurrentMealPeriod({
														// 	variables: { outletID: outlet?.ID },
														// });
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => {
										setOpenSwitch(false);
										reset();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
