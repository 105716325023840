import { PosPermission } from 'generated/graphql';

export interface RenderPermissionTree {
	id: PosPermission;
	label: string;
	children?: RenderPermissionTree[];
	layer?: string;
	selected?: number;
}

export const RestaurantPerm: RenderPermissionTree = {
	id: PosPermission.RestaurantIcon,
	label: 'Restaurant',
	layer: '1',
	children: [
		{
			id: PosPermission.HomeTableLayoutView,
			label: 'Home',
			children: [
				{
					id: PosPermission.HomeTableLayoutNewOrderCreate,
					label: 'New Order',
				},
				{
					id: PosPermission.HomeTableLayoutAddEditOrderItemView,
					label: 'Add & Edit Order Item',
					children: [
						{
							id:
								PosPermission.HomeTableLayoutAddEditOrderItemVoidOrderItemVoid,
							label: 'Void Order Item',
						},
					],
				},
				{
					id: PosPermission.HomeTableLayoutEditOrderInfoView,
					label: 'Edit Order Info',
				},
				{
					id: PosPermission.HomeTableLayoutChangeTransferTableView,
					label: 'Change/Transfer Table',
				},
				{
					id: PosPermission.HomeTableLayoutItemTransferView,
					label: 'Item Transfer',
				},
				{
					id: PosPermission.HomeTableLayoutBillItemDiscountView,
					label: 'Bill/Item Discount',
					children: [
						{
							id:
								PosPermission.HomeTableLayoutBillItemDiscountCreateOrderDiscountCreate,
							label: 'Create Order Discount',
						},
						{
							id:
								PosPermission.HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate,
							label: 'Create Order Item Discount',
						},
					],
				},
				{
					id: PosPermission.HomeTableLayoutBillSettlementView,
					label: 'Bill Settlement',
				},
				{
					id: PosPermission.HomeTableLayoutCloseTableView,
					label: 'Close Table',
				},
			],
		},
		{
			id: PosPermission.TakeawayShow,
			label: 'Takeaway',
			children: [
				{
					id: PosPermission.TakeawayCreate,
					label: 'Create',
				},
				{
					id: PosPermission.TakeawayTakeawayVoidOrderItemVoid,
					label: 'Void Order Item',
				},
				{
					id: PosPermission.TakeawayPickUpUpdate,
					label: 'Pickup Order',
				},
				{
					id: PosPermission.TakeawayAddOrderCreate,
					label: 'Add Order',
				},
				{
					id: PosPermission.TakeawayCancelOrderUpdate,
					label: 'Cancel Order',
				},
				{
					id: PosPermission.TakeawayMakePaymentCreate,
					label: 'Make Payment',
				},
			],
		},
		{
			id: PosPermission.CommonSettingShow,
			label: 'Common Settings',
			children: [
				{
					id: PosPermission.CommonSettingTaxPolicyShow,
					label: 'Tax Policy',
					children: [
						{
							id: PosPermission.CommonSettingTaxPolicyCreate,
							label: 'Create',
						},
						{
							id: PosPermission.CommonSettingTaxPolicyUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.CommonSettingTaxPolicyDelete,
							label: 'Delete',
						},
					],
				},
				{
					id: PosPermission.CommonSettingTaxSchemePolicyShow,
					label: 'Tax Scheme Policy',
					children: [
						{
							id: PosPermission.CommonSettingTaxSchemePolicyCreate,
							label: 'Create',
						},
						{
							id: PosPermission.CommonSettingTaxSchemePolicyUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.CommonSettingTaxSchemePolicyDelete,
							label: 'Delete',
						},
					],
				},
			],
		},
		{
			id: PosPermission.GeneralSettingShow,
			label: 'General Settings',
			children: [
				{
					id: PosPermission.GeneralSettingAppointedOfficerShow,
					label: 'Appointed Officer',
					children: [
						{
							id: PosPermission.GeneralSettingAppointedOfficerCreate,
							label: 'Create',
						},
						{
							id: PosPermission.GeneralSettingAppointedOfficerUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.GeneralSettingAppointedOfficerDelete,
							label: 'Delete',
						},
					],
				},
				{
					id: PosPermission.GeneralSettingAuthorisedStaffShow,
					label: 'Authorized Staff',
					children: [
						{
							id: PosPermission.GeneralSettingAuthorisedStaffCreate,
							label: 'Create',
						},
						{
							id: PosPermission.GeneralSettingAuthorisedStaffUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.GeneralSettingAuthorisedStaffDelete,
							label: 'Delete',
						},
					],
				},
				{
					id: PosPermission.GeneralSettingAccessSecurityShow,
					label: 'Access Security',
					children: [
						{
							id: PosPermission.GeneralSettingAccessSecurityUsersShow,
							label: 'Users',
							children: [
								{
									id: PosPermission.GeneralSettingAccessSecurityUsersCreate,
									label: 'Create',
								},
								{
									id: PosPermission.GeneralSettingAccessSecurityUsersUpdate,
									label: 'Update',
								},
							],
						},
						{
							id: PosPermission.GeneralSettingAccessSecurityRolesShow,
							label: 'Roles',
							children: [
								{
									id: PosPermission.GeneralSettingAccessSecurityRolesCreate,
									label: 'Create',
								},
								{
									id: PosPermission.GeneralSettingAccessSecurityRolesUpdate,
									label: 'Update',
								},
								{
									id: PosPermission.GeneralSettingAccessSecurityRolesDelete,
									label: 'Delete',
								},
							],
						},
					],
				},
				{
					id: PosPermission.GeneralSettingRoundingPolicyShow,
					label: 'Rounding Policy',
					children: [
						{
							id: PosPermission.GeneralSettingRoundingPolicyUpdate,
							label: 'Update',
						},
					],
				},
				{
					id: PosPermission.GeneralSettingUomShow,
					label: 'UOM',
					children: [
						{
							id: PosPermission.GeneralSettingUomCreate,
							label: 'Create',
						},
						{
							id: PosPermission.GeneralSettingUomUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.GeneralSettingUomDelete,
							label: 'Delete',
						},
					],
				},
				{
					id: PosPermission.GeneralSettingUomConversionShow,
					label: 'UOM Conversion',
					children: [
						{
							id: PosPermission.GeneralSettingUomConversionCreate,
							label: 'Create',
						},
						{
							id: PosPermission.GeneralSettingUomConversionUpdate,
							label: 'Update',
						},
						{
							id: PosPermission.GeneralSettingUomConversionDelete,
							label: 'Delete',
						},
					],
				},
			],
		},
		{
			id: PosPermission.OutletSettingShow,
			label: 'Outlet Settings',
			children: [
				{
					id: PosPermission.OutletSettingCreate,
					label: 'Create Outlet',
				},
				{
					id: PosPermission.OutletSettingOutletProfileUpdate,
					label: 'Outlet Profile',
				},
				{
					id: PosPermission.OutletSettingOutletPolicyShow,
					label: 'Outlet Policy',
					children: [
						{
							id: PosPermission.OutletSettingOutletPolicyPatronSetupShow,
							label: 'Patron Setup',
						},
						{
							id: PosPermission.OutletSettingOutletPolicyTaxSetupShow,
							label: 'Tax Setup',
							children: [
								{
									id: PosPermission.OutletSettingOutletPolicyTaxSetupTaxShow,
									label: 'Tax',
								},
								{
									id:
										PosPermission.OutletSettingOutletPolicyTaxSetupServiceChargeShow,
									label: 'Service Charge',
								},
							],
						},
						{
							id: PosPermission.OutletSettingOutletPolicyReasonSetupShow,
							label: 'Reason Setup',
						},
					],
				},
				{
					id: PosPermission.OutletSettingTableShow,
					label: 'Table',
				},
				{
					id: PosPermission.OutletSettingQrCodeShow,
					label: 'QR Code',
				},
				{
					id: PosPermission.OutletSettingKitchenPrinterShow,
					label: 'Kitchen Printer',
				},
				{
					id: PosPermission.OutletSettingReceiptPrinterShow,
					label: 'Receipt Printer',
				},
				{
					id: PosPermission.OutletSettingMealPeriodShow,
					label: 'Meal Period',
				},
				{
					id: PosPermission.OutletSettingMajorFamilyShow,
					label: 'Major & Family',
				},
				{
					id: PosPermission.OutletSettingStandardMenuOptionShow,
					label: 'Standard Menu Option',
				},
				{
					id: PosPermission.OutletSettingMenuItemsShow,
					label: 'Menu Items',
				},
				{
					id: PosPermission.OutletSettingSetMenuShow,
					label: 'Set Menu',
				},
				{
					id: PosPermission.OutletSettingDigitalMenuPricingShow,
					label: 'Digital Menu & Pricing',
				},
				{
					id: PosPermission.OutletSettingMenuSchedulingShow,
					label: 'Menu Scheduling',
				},
				{
					id: PosPermission.OutletSettingPromotionalMenuPricingShow,
					label: 'Promotional Menu & Pricing',
				},
				{
					id: PosPermission.OutletSettingDailyMenuItemAvailabilityShow,
					label: 'Daily Menu Item Availability',
				},
				{
					id: PosPermission.OutletSettingPromoCodeShow,
					label: 'Promo Code',
				},
				{
					id: PosPermission.OutletSettingDiscountTypeShow,
					label: 'Discount Type',
				},
				{
					id: PosPermission.OutletSettingStopSalesItemsShow,
					label: 'Stop Sales Items',
				},
				{
					id: PosPermission.OutletSettingOutletRoleAssignmentShow,
					label: 'Outlet Role Assignment',
				},
				{
					id: PosPermission.OutletSettingPaymentTypeShow,
					label: 'Payment Type',
				},
				{
					id: PosPermission.OutletSettingThirdPartyInterfaceSetupShow,
					label: '3rd Party Interface Setup',
				},
			],
		},
		{
			id: PosPermission.BillListingShow,
			label: 'Bill Listing',
			children: [
				{
					id: PosPermission.BillListingVoidPayment,
					label: 'Void Payment',
				},
				{
					id: PosPermission.BillListingVoidBill,
					label: 'Void Bill',
				},
				{
					id: PosPermission.BillListingPrint,
					label: 'Print',
				},
			],
		},
		// {
		// 	id: PosPermission.ReservationShow,
		// 	label: 'Reservations',
		// 	children: [
		// 		{
		// 			id: PosPermission.ReservationCreate,
		// 			label: 'Create',
		// 		},
		// 		{
		// 			id: PosPermission.ReservationUpdate,
		// 			label: 'Update',
		// 		},
		// 		{
		// 			id: PosPermission.ReservationAssignTable,
		// 			label: 'Assign Table',
		// 		},
		// 		{
		// 			id: PosPermission.ReservationCancel,
		// 			label: 'Cancel',
		// 		},
		// 		{
		// 			id: PosPermission.ReservationReinstate,
		// 			label: 'Reinstate',
		// 		},
		// 	],
		// },
		// {
		// 	id: PosPermission.OnlineOrdersShow,
		// 	label: 'Online Orders',
		// },
		{
			id: PosPermission.UtilityShow,
			label: 'Utility',
			children: [
				{
					id: PosPermission.UtilityDuplicateMenuDuplicate,
					label: 'Duplicate Menu',
				},
				{
					id: PosPermission.UtilityUpdateDailyMenuItemUpdate,
					label: 'Update Daily Menu Item',
				},
			],
		},
		// {
		// 	id: PosPermission.BizAnalyticsShow,
		// 	label: 'Business Analytics',
		// 	children: [
		// 		{
		// 			id: PosPermission.BizAnalyticsMpsAnalysisShow,
		// 			label: 'Duplicate Menu',
		// 		},
		// 		{
		// 			id: PosPermission.BizAnalyticsWscAnalysisShow,
		// 			label: 'Update Daily Menu Item',
		// 		},
		// 	],
		// },
		{
			id: PosPermission.BizAnalyticsShow,
			label: 'Business Analytics',
			children: [
				{
					id: PosPermission.BizAnalyticsOscAnalysisShow,
					label: 'Outlet Sales & Collection Analysis',
				},
				{
					id: PosPermission.BizAnalyticsMpsAnalysisShow,
					label: 'Meal Period & Collection Analysis',
				},
				{
					id: PosPermission.BizAnalyticsWscAnalysisShow,
					label: 'Waiter Sales & Collection Analysis',
				},
				{
					id: PosPermission.BizAnalyticsFmviAnalysisShow,
					label: 'Fast-Moving Menu Item Analysis',
				},
				{
					id: PosPermission.BizAnalyticsSmviAnalysisShow,
					label: 'Slow-Moving Menu Item Analysis',
				},
				{
					id: PosPermission.BizAnalyticsPtAnalysisShow,
					label: 'Patron Type Analysis',
				},
				{
					id: PosPermission.BizAnalyticsDtAnalysisShow,
					label: 'Discount Type Analysis',
				},
				{
					id: PosPermission.BizAnalyticsAmtAnalysisShow,
					label: 'Average Meal Time Analysis',
				},
			],
		},
		{
			id: PosPermission.DigitalReportingShow,
			label: 'Digital Reporting',
			children: [
				{
					id: PosPermission.DigitalReportingOutletSalesRevenueShow,
					label: 'Outlet Sales Revenue',
				},
				{
					id: PosPermission.DigitalReportingMealPeriodCollectionShow,
					label: 'Meal Period Collection',
				},
				{
					id: PosPermission.DigitalReportingProductionAnalysisSummaryShow,
					label: 'Production Analysis Summary',
				},
				{
					id: PosPermission.DigitalReportingProductionAnalysisByOutletShow,
					label: 'Production Analysis By Outlet',
				},
				{
					id: PosPermission.DigitalReportingDailyEarningReportShow,
					label: 'Daily Earning Report',
				},
				{
					id: PosPermission.DigitalReportingCashierCollectionReportShow,
					label: 'Cashier Collection Report',
				},
				{
					id: PosPermission.DigitalReportingDayEndReportShow,
					label: 'Day End Report',
				},
				{
					id: PosPermission.DigitalReportingBillTransactionListingShow,
					label: 'Bill Transaction Listing',
				},
				{
					id: PosPermission.DigitalReportingDiscountListingShow,
					label: 'Discount Listing',
				},
				{
					id: PosPermission.DigitalReportingAuditUserLoggingSystemShow,
					label: 'User Activity Log Report',
				},
				{
					id: PosPermission.DigitalReportingVoidedTransactionListingShow,
					label: 'Voided Transactions Listing',
				},
				{
					id: PosPermission.DigitalReportingMealPeriodSalesShow,
					label: 'Meal Period Sales',
				},
				{
					id: PosPermission.DigitalReportingDownloadedGlListingShow,
					label: 'Downloaded GL Listing',
				},
				{
					id: PosPermission.DigitalReportingSalesServicesListingShow,
					label: 'Sales & Services Listing',
				},
				{
					id: PosPermission.DigitalReportingDownloadedGlListingShow,
					label: 'Menu Items File Listing',
				},
				{
					id: PosPermission.DigitalReportingDigitalMenuPricingListingShow,
					label: 'Digital Menu & pricing Listing',
				},
			],
		},
		{
			id: PosPermission.DigitalDocumentShow,
			label: 'Digital Document',
			children: [
				{
					id: PosPermission.DigitalDocumentPrint,
					label: 'Print',
				},
				{
					id: PosPermission.DigitalDocumentShare,
					label: 'Share',
				},
			],
		},
		{
			id: PosPermission.AccountJournalsShow,
			label: 'Account Journals',
			children: [
				{
					id: PosPermission.AccountJournalsAccountsMappingShow,
					label: 'Accounts Mapping',
					children: [
						{
							id:
								PosPermission.AccountJournalsAccountsMappingOutletGlInterfaceShow,
							label: 'Outlet GL Interface',
							children: [
								{
									id:
										PosPermission.AccountJournalsAccountsMappingOutletGlInterfaceUpdate,
									label: 'Edit',
								},
							],
						},
						{
							id: PosPermission.AccountJournalsAccountsMappingBankAccountShow,
							label: 'Bank Account',
							children: [
								{
									id:
										PosPermission.AccountJournalsAccountsMappingBankAccountCreate,
									label: 'Create',
								},
								{
									id:
										PosPermission.AccountJournalsAccountsMappingBankAccountUpdate,
									label: 'Update',
								},
								{
									id:
										PosPermission.AccountJournalsAccountsMappingBankAccountDelete,
									label: 'Delete',
								},
							],
						},
					],
				},
				{
					id: PosPermission.AccountJournalsGlJournalShow,
					label: 'GL Journal',
				},
				{
					id: PosPermission.AccountJournalsCbTransactionShow,
					label: 'CB Transaction',
				},
			],
		},
	],
};

export const KitchenPerm: RenderPermissionTree = {
	id: PosPermission.KitchenIcon,
	label: 'Kitchen',
	layer: '1',
	children: [
		{
			id: PosPermission.KitchenUpdateOrderItemUpdate,
			label: 'Update Order Item',
		},
		{
			id: PosPermission.KitchenCloseOrderUpdate,
			label: 'Close Order',
		},
	],
};

export const PrinterServerPerm: RenderPermissionTree = {
	id: PosPermission.PrinterServerIcon,
	label: 'Printer Server',
	layer: '1',
};
// export const HomePerm: RenderPermissionTree =
// {
// 	id: PosPermission.HomeTableLayoutView,
// 	label: 'Home',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.HomeTableLayoutNewOrderCreate,
// 			label: 'New Order',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutAddEditOrderItemView,
// 			label: 'Add & Edit Order Item',
// 			children: [
// 				{
// 					id: PosPermission.HomeTableLayoutAddEditOrderItemVoidOrderItemVoid,
// 					label: 'Void Order Item',
// 				},
// 			],
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutEditOrderInfoView,
// 			label: 'Edit Order Info',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutChangeTransferTableView,
// 			label: 'Change/Transfer Table',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutItemTransferView,
// 			label: 'Item Transfer',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutBillItemDiscountView,
// 			label: 'Bill/Item Discount',
// 			children: [
// 				{
// 					id:
// 						PosPermission.HomeTableLayoutBillItemDiscountCreateOrderDiscountCreate,
// 					label: 'Create Order Discount',
// 				},
// 				{
// 					id:
// 						PosPermission.HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate,
// 					label: 'Create Order Item Discount',
// 				},
// 			],
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutBillSettlementView,
// 			label: 'Bill Settlement',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutCloseTableView,
// 			label: 'Close Table',
// 		},
// 		{
// 			id: PosPermission.HomeTableLayoutTakeAwayView,
// 			label: 'Takeaway Order',
// 			children: [
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayCreate,
// 					label: 'Create',
// 				},
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayVoidOrderItemVoid,
// 					label: 'Void Order Item',
// 				},
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayPickUpUpdate,
// 					label: 'Pickup Order',
// 				},
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayAddOrderCreate,
// 					label: 'Add Order',
// 				},
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayCancelOrderUpdate,
// 					label: 'Cancel Order',
// 				},
// 				{
// 					id: PosPermission.HomeTableLayoutTakeAwayMakePaymentCreate,
// 					label: 'Make Payment',
// 				},
// 			],
// 		},
// 	],
// };

// export const GeneralSettingPerm: RenderPermissionTree =
// {
// 	id: PosPermission.GeneralSettingShow,
// 	label: 'General Settings',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.GeneralSettingAppointedOfficerShow,
// 			label: 'Appointed Officer',
// 			children: [
// 				{
// 					id: PosPermission.GeneralSettingAppointedOfficerCreate,
// 					label: 'Create',
// 				},
// 				{
// 					id: PosPermission.GeneralSettingAppointedOfficerUpdate,
// 					label: 'Update',
// 				},
// 				{
// 					id: PosPermission.GeneralSettingAppointedOfficerDelete,
// 					label: 'Delete',
// 				},
// 			],
// 		},
// 		{
// 			id: PosPermission.GeneralSettingAuthorisedStaffShow,
// 			label: 'Authorized Staff',
// 			children: [
// 				{
// 					id: PosPermission.GeneralSettingAuthorisedStaffCreate,
// 					label: 'Create',
// 				},
// 				{
// 					id: PosPermission.GeneralSettingAuthorisedStaffUpdate,
// 					label: 'Update',
// 				},
// 				{
// 					id: PosPermission.GeneralSettingAuthorisedStaffDelete,
// 					label: 'Delete',
// 				},
// 			],
// 		},
// 		{
// 			id: PosPermission.GeneralSettingAccessSecurityShow,
// 			label: 'Access Security',
// 			children: [
// 				{
// 					id: PosPermission.GeneralSettingAccessSecurityUsersShow,
// 					label: 'Users',
// 					children: [
// 						{
// 							id: PosPermission.GeneralSettingAccessSecurityUsersCreate,
// 							label: 'Create',
// 						},
// 						{
// 							id: PosPermission.GeneralSettingAccessSecurityUsersUpdate,
// 							label: 'Update',
// 						},
// 					],
// 				},
// 				{
// 					id: PosPermission.GeneralSettingAccessSecurityRolesShow,
// 					label: 'Roles',
// 					children: [
// 						{
// 							id: PosPermission.GeneralSettingAccessSecurityRolesCreate,
// 							label: 'Create',
// 						},
// 						{
// 							id: PosPermission.GeneralSettingAccessSecurityRolesUpdate,
// 							label: 'Update',
// 						},
// 						{
// 							id: PosPermission.GeneralSettingAccessSecurityRolesDelete,
// 							label: 'Delete',
// 						},
// 					],
// 				},
// 				{
// 					id: PosPermission.GeneralSettingRoundingPolicyUpdate,
// 					label: 'Rounding Policy',
// 				},
// 			],
// 		},
// 	],
// };

// export const OutletSettingPerm: RenderPermissionTree =
// {
// 	id: PosPermission.OutletSettingShow,
// 	label: 'Outlet Settings',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.OutletSettingCreate,
// 			label: 'Create Outlet',
// 		},
// 		{
// 			id: PosPermission.OutletSettingOutletProfileUpdate,
// 			label: 'Outlet Profile',
// 		},
// 		{
// 			id: PosPermission.OutletSettingOutletPolicyShow,
// 			label: 'Outlet Policy',
// 		},
// 		{
// 			id: PosPermission.OutletSettingTableShow,
// 			label: 'Table',
// 		},
// 		{
// 			id: PosPermission.OutletSettingQrCodeShow,
// 			label: 'QR Code',
// 		},
// 		{
// 			id: PosPermission.OutletSettingKitchenPrinterShow,
// 			label: 'Kitchen Printer',
// 		},
// 		{
// 			id: PosPermission.OutletSettingMealPeriodShow,
// 			label: 'Meal Period',
// 		},
// 		{
// 			id: PosPermission.OutletSettingMajorFamilyShow,
// 			label: 'Major & Family',
// 		},
// 		{
// 			id: PosPermission.OutletSettingStandardMenuOptionShow,
// 			label: 'Standard Menu Option',
// 		},
// 		{
// 			id: PosPermission.OutletSettingMenuItemsShow,
// 			label: 'Menu Items',
// 		},
// 		{
// 			id: PosPermission.OutletSettingSetMenuShow,
// 			label: 'Set Menu',
// 		},
// 		{
// 			id: PosPermission.OutletSettingDigitalMenuPricingShow,
// 			label: 'Digital Menu & Pricing',
// 		},
// 		{
// 			id: PosPermission.OutletSettingMenuSchedulingShow,
// 			label: 'Menu Scheduling',
// 		},
// 		{
// 			id: PosPermission.OutletSettingPromotionalMenuPricingShow,
// 			label: 'Promotional Menu & Pricing',
// 		},
// 		{
// 			id: PosPermission.OutletSettingDailyMenuItemAvailabilityShow,
// 			label: 'Daily Menu Item Availability',
// 		},
// 		{
// 			id: PosPermission.OutletSettingPromoCodeShow,
// 			label: 'Promo Code',
// 		},
// 		{
// 			id: PosPermission.OutletSettingDiscountTypeShow,
// 			label: 'Discount Type',
// 		},
// 		{
// 			id: PosPermission.OutletSettingStopSalesItemsShow,
// 			label: 'Stop Sales Items',
// 		},
// 		{
// 			id: PosPermission.OutletSettingOutletRoleAssignmentShow,
// 			label: 'Outlet Role Assignment',
// 		},
// 		{
// 			id: PosPermission.OutletSettingPaymentTypeShow,
// 			label: 'Payment Type',
// 		},
// 		{
// 			id: PosPermission.OutletSettingThirdPartyInterfaceSetupShow,
// 			label: '3rd Party Interface Setup',
// 		},
// 	],
// };

// export const BillListingPerm: RenderPermissionTree = {
// 	id: PosPermission.BillListingShow,
// 	label: 'Bill Listing',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.BillListingVoidPayment,
// 			label: 'Void Payment',
// 		},
// 		{
// 			id: PosPermission.BillListingVoidBill,
// 			label: 'Void Bill',
// 		},
// 		{
// 			id: PosPermission.BillListingPrint,
// 			label: 'Print',
// 		},
// 	],
// };

// export const ReservationPerm: RenderPermissionTree =
// {
// 	id: PosPermission.ReservationShow,
// 	label: 'Reservations',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.ReservationCreate,
// 			label: 'Create',
// 		},
// 		{
// 			id: PosPermission.ReservationUpdate,
// 			label: 'Update',
// 		},
// 		{
// 			id: PosPermission.ReservationAssignTable,
// 			label: 'Assign Table',
// 		},
// 		{
// 			id: PosPermission.ReservationCancel,
// 			label: 'Cancel',
// 		},
// 	],
// };

// export const UtilityPerm: RenderPermissionTree =
// {
// 	id: PosPermission.UtilityShow,
// 	label: 'Utility',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.UtilityDuplicateMenuDuplicate,
// 			label: 'Duplicate Menu',
// 		},
// 		{
// 			id: PosPermission.UtilityUpdateDailyMenuItemUpdate,
// 			label: 'Update Daily Menu Item',
// 		},
// 	],
// };

// export const BusinessInsightPerm: RenderPermissionTree =
// {
// 	id: PosPermission.BizInsightShow,
// 	label: 'Business Insight',
// 	layer: '1',
// 	children: [
// 		{
// 			id: PosPermission.BizInsightBusinessAnalyticsShow,
// 			label: 'Business Analytics',
// 			children: [
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsOscAnalysisShow,
// 					label: 'Outlet Sales & Collection Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsMpsAnalysisShow,
// 					label: 'Meal Period & Collection Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsWscAnalysisShow,
// 					label: 'Waiter Sales & Collection Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsFmviAnalysisShow,
// 					label: 'Fast-Moving Menu Item Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsSmviAnalysisShow,
// 					label: 'Slow-Moving Menu Item Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsPtAnalysisShow,
// 					label: 'Patron Type Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsDtAnalysisShow,
// 					label: 'Discount Type Analysis',
// 				},
// 				{
// 					id: PosPermission.BizInsightBusinessAnalyticsAmtAnalysisShow,
// 					label: 'Average Meal Time Analysis',
// 				},
// 			],
// 		},
// 		{
// 			id: PosPermission.BizInsightDigitalDocumentShow,
// 			label: 'Digital Document',
// 		},
// 		{
// 			id: PosPermission.BizInsightDigitalBillListingShow,
// 			label: 'Digital Bill Listing',
// 			children: [
// 				{
// 					id: PosPermission.BizInsightDigitalBillListingPrint,
// 					label: 'Print',
// 				},
// 				{
// 					id: PosPermission.BizInsightDigitalBillListingShare,
// 					label: 'Share',
// 				},
// 			],
// 		},
// 	],
// };
