//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import React from 'react';
import { DigitalReportingSubmenu } from './DigitalReportingSubmenu';
import { BillTransactionLsiting } from './Report/BillTransactionListing';
import { CashierCollectionReport } from './Report/CashierCollectionReport';
import { DailyEarningReport } from './Report/DailyEarningReport';
import { DayEndReport } from './Report/DayEndReport';
import { MealPeriodCollectionReport } from './Report/MealPeriodCollectionReport';
import { OutletSalesRevenueReport } from './Report/OutletSalesRevenueReport';
import { ProductionAnalysisByMajorFamilyItemReport } from './Report/ProductionAnalysisByMajorFamilyItemReport';
import { ProductionAnalysisByOutletReport } from './Report/ProductionAnalysisByOutletReport';
import { DiscountListing } from './Report/DiscountListing';
import { AuditUserLoggingSystem } from './Report/AuditUserLoggingSystem';
import { VoidedTransactionListing } from './Report/VoidedTransactionListing';
import { MealPeriodSalesReport } from './Report/MealPeriodSalesReport';
import { DownloadedGLListing } from './Report/DownloadedGLListing';
import { SalesAndServicesReport } from './Report/SalesAndServicesReport';
import { MenuItemsMasterFileListing } from './Report/MasterItemsFileListingReport';
import { DigitalMenuPricingForm } from '../OutletSettings/DigitalMenuAndPricing/DigitalMenuPricingForm';
import { DigitalMenuPricingListing } from './Report/DigitalMenuPricingListingReport';

const digitalReportingRoutes = [
	{
		props: {
			exact: true,
			//path: '/menu/outlet-app/business-insight/digital-reporting',
			path: '/menu/outlet-app/:outletID/digital-reporting',
		},
		component: <DigitalReportingSubmenu />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/outlet-sales-revenue',
			path: '/menu/outlet-app/:outletID/digital-reporting/outlet-sales-revenue',
		},
		component: <OutletSalesRevenueReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/meal-period-collection',
			path:
				'/menu/outlet-app/:outletID/digital-reporting/meal-period-collection',
		},
		component: <MealPeriodCollectionReport />,
	},
	{
		props: {
			exact: true,
			// path: '/menu/outlet-app/business-insight/digital-reporting/daily-earning',
			path: '/menu/outlet-app/:outletID/digital-reporting/daily-earning',
		},
		component: <DailyEarningReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/production-analysis-outlet',
			path:
				'/menu/outlet-app/:outletID/digital-reporting/production-analysis-outlet',
		},
		component: <ProductionAnalysisByOutletReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/production-analysis-major-family-item',
			path:
				'/menu/outlet-app/:outletID/digital-reporting/production-analysis-major-family-item',
		},
		component: <ProductionAnalysisByMajorFamilyItemReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/production-analysis-major-family-item',
			path: '/menu/outlet-app/:outletID/digital-reporting/cashier-collection',
		},
		component: <CashierCollectionReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/production-analysis-major-family-item',
			path: '/menu/outlet-app/:outletID/digital-reporting/day-end',
		},
		component: <DayEndReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/production-analysis-major-family-item',
			path: '/menu/outlet-app/:outletID/digital-reporting/day-end',
		},
		component: <DayEndReport />,
	},
	{
		props: {
			exact: true,
			// path:
			// 	'/menu/outlet-app/business-insight/digital-reporting/outlet-sales-revenue',
			path: '/menu/outlet-app/:outletID/digital-reporting/bill-trans-list',
		},
		component: <BillTransactionLsiting />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/digital-reporting/discount-list',
		},
		component: <DiscountListing />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/digital-reporting/user-activity-log-report',
		},
		component: <AuditUserLoggingSystem />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/digital-reporting/voided-trans-list',
		},
		component: <VoidedTransactionListing />,
	},
	{
		props: {
			exact: true,

			path: '/menu/outlet-app/:outletID/digital-reporting/meal-period-sales',
		},
		component: <MealPeriodSalesReport />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/digital-reporting/download-gl-listing',
		},
		component: <DownloadedGLListing />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/digital-reporting/sales-and-services',
		},

		component: <SalesAndServicesReport />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/digital-reporting/menu-items-master-file-listing',
		},
		component: <MenuItemsMasterFileListing />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/digital-reporting/digital-menu-pricing-listing',
		},
		component: <DigitalMenuPricingListing />,
	},
];

export default digitalReportingRoutes;
